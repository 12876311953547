<script>
/**
 * Topbar component
 */

import { authUserMethods } from "../state/helpers/authuser"
import {
  required,
} from "vuelidate/lib/validators";
import avatar from "../assets/images/default.png"
import toast from "../state/modules/toast";
import Swal from "sweetalert2";
import { formatDateMixin } from "../router/mixins/formatDateMixin";
import { companyInfoMixin } from "../router/mixins/companyInfoMixin";
const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file.file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})
export default {
  mixins: [formatDateMixin, companyInfoMixin],
  data() {
    return {
      avatar,
      profile: {
        first_name: '',
        last_name: '', 
        email: '',
        address: '',
        city: '',
        phone: '',
        zip_code: '',
        state: '',
      },
      is_show_password: false,
      is_password_match: false,
      password: {old_password: "", new_password: "", confirm_password: ""},
      tab_items: [
        { id: 1, title: "Infomation" },
        { id: 2, title: "Security" },
      ],
      is_tab_active: 1,
      submitted: false,
    };
  },
  validations: {
    profile: {
      first_name: {required},
      last_name: {required},
      email: {required},
      phone: {required},
    },
    // password: {
    //   old_password: {required},
    //   new_password: {required},
    // }
  },
  mounted() {
    this.getUserData()
  },
  methods: {
    ...authUserMethods,

    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    routeToHome() {
      if(this.profile.user_type === 'admin') {
              this.$router.push('/admin-dashboard').catch(() => {}) 
            } else if(this.profile.user_type === 'staff' && this.profile.is_manager === 1) {
              this.$router.push('/').catch(() => {}) 
            } else if(this.profile.user_type  === 'staff' && this.profile.is_manager === 0) {
              this.$router.push('/staff-dashboard').catch(() => {})
            }
    },
    logout() {
      this.logoutUser()
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    getUserData() {
      const user = JSON.parse(localStorage.getItem('sweet_homes_america_user'))
      user.is_profile_update = true;
      this.profile = user
    },
    getImage(image) {
      if (image !== null || image !== '') {
        return process.env.VUE_APP_STORAGE_PATH + image
      }
      return image
    },
    showModal() {
      this.$bvModal.show('profile-modal')
    },
    hide() {
      this.$bvModal.hide('profile-modal')
    },
    showFormContent(id) {
      this.is_tab_active = id;
    },
    upload() {
      const el = document.createElement('input')
      el.type = 'file'
      if (this.multiple) {
        el.multiple = true
      }
      el.accept = this.accept
      el.onchange = event => {
        const { files } = event.target
        if (this.multiple) {
          this.selectedFiles = [...this.selectedFiles, ...files]
          this.onFileUpload(this.selectedFiles)
        } else {
          const reader = new FileReader()
          reader.readAsDataURL(files[0])
          reader.onload = () => {
            this.onFileUpload({
              file: files[0],
              dataUrl: reader.result,
            })
          }
        }
      }
      el.click()
    },
    onFileUpload(file) {
      // this.user.avatar = file.dataUrl
      const fd = new FormData()
      fd.append('image', file.file)
      const dom = this

      // eslint-disable-next-line func-names
      return (async function () {
        const data = await toBase64(file)
        const fileItem = { file: data }
        dom.profile.image = [fileItem]
        if (dom.profile.user_type === 'admin') {
          dom.updateAdmin(dom.profile).then(() => {
            dom.getUserData()
            delete dom.profile.image
          })
        } else if (dom.profile.user_type === 'manager') {
          dom.updateManager(dom.profile).then(() => {
            dom.getUserData()
            delete dom.profile.image
          })
        } else if (dom.profile.user_type === 'staff') {
          dom.updateStaff(dom.profile).then(() => {
            dom.getUserData()
            delete dom.profile.image
          })
        } else if (dom.profile.user_type === 'property_owner') {
          dom.updatePropertyOwner(dom.profile).then(() => {
            dom.getUserData()
            delete dom.profile.image
          })
        } else if (dom.profile.user_type === 'tenant') {
          dom.updateTenant(dom.profile).then(() => {
            dom.getUserData()
            delete dom.profile.image
          })
        }
     
      }())
    },
    saveProfile() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        // eslint-disable-next-line no-console
        console.log(this.$v.$invalid)
        return;
      } else {
        
        if (this.profile.user_type === 'admin') {
          this.updateAdmin(this.profile).then(() => {
            this.getUserData()
          })
        } else if (this.profile.user_type === 'manager') {
          this.updateManager(this.profile).then(() => {
            this.getUserData()

          })
        } else if (this.profile.user_type === 'staff') {
          this.updateStaff(this.profile).then(() => {
            this.getUserData()
 
          })
        } else if (this.profile.user_type === 'property_owner') {
          this.updatePropertyOwner(this.profile).then(() => {
            this.getUserData()
 
          })
        } else if (this.profile.user_type === 'tenant') {
          this.updateTenant(this.profile).then(() => {
            this.getUserData()
          })
        }
      }
      this.submitted = false;
    },
    savePassword() {
      this.submitted = true;
      // stop here if form is invalid
      if(this.password.new_password.length > 0 && this.password.old_password.length > 0) {
        const data = {old_password: this.password.old_password, new_password: this.password.new_password, id: this.profile.account}
        this.updateUser(data).then(() => {
          this.getUserData( )
          this.password = {old_password: "", new_password: ""}
        })
      } else {
        toast("please complete all fields", 'error')
      }
    },
    handleViewPassword() {
      this.is_show_password = !this.is_show_password
    },
    handleConfirmPasswordCheck(event) {
      const validPassword = event.target.value
      
      if(validPassword === this.password.new_password) {
        this.is_password_match = false
      }else if(validPassword == ""){
        this.is_password_match = false
      } else {
        this.is_password_match = true
      }
    },
    onidle() {
      this.logout()
    },
    onremind(time) {
      // alert seconds remaining to 00:00
      this.idleWarning(time)
    },
    idleWarning(time) {
      Swal.fire({
        title: 'Warning!',
        text: `You have left this app idle for 10mins, left with ${time} seconds to logout the app.`,
        icon: 'warning',
        timer: 6000,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
};
</script>
<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box" @click="routeToHome">
          <div class="logo logo-dark">
            <span class="logo-sm">
              <h4 class="text-white">SNS </h4>
              <!-- <img src="@/assets/images/logo-sm.png" alt height="22" /> -->
            </span>
            <span class="logo-lg">
              <h4 class="text-white">SNS </h4>
              <!-- <img src="@/assets/images/logo-dark.png" alt height="17" /> -->
            </span>
          </div>

          <div class="logo logo-light">
            <span class="logo-sm">
              <h4 class="text-white">SNS </h4>
              <!-- <img src="@/assets/images/logo-sm.png" alt height="22" /> -->
            </span>
            <span class="logo-lg">
              <h4 class="text-white mt-3">Safe and Sound <br />Real Estate Inc</h4>
              <!-- <img src="@/assets/images/logo-light.png" alt height="18" /> -->
            </span>
          </div>
        </div>

        <button type="button" class="btn btn-sm px-3 font-size-24 header-item" id="vertical-menu-btn"
          @click="toggleMenu()">
          <i class="mdi mdi-menu"></i>
        </button>
      </div>
      <v-idle
      :loop="false"
      :reminders="[10]"
      :wait="5"
      :duration="900"
      style="visibility: hidden;"
      @idle="onidle"
      @remind="onremind"
    />
      <div class="d-flex">
        <!-- <b-dropdown
          class="d-inline-block"
          toggle-class="header-item noti-icon"
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          right
          variant="white"
        >
          <template v-slot:button-content>
            <i class="mdi mdi-bell-outline"></i>
            <span class="badge bg-danger rounded-pill">3</span>
          </template>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="m-0 font-size-16">Notifications (258)</h5>
              </div>
            </div>
          </div>
          <div data-simplebar style="max-height: 230px;">
            <a href="javascript:void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <span
                      class="avatar-title bg-success rounded-circle font-size-16"
                    >
                      <i class="mdi mdi-cart-outline"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">Your order is placed</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      Dummy text of the printing and typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <span
                      class="avatar-title bg-warning rounded-circle font-size-16"
                    >
                      <i class="mdi mdi-message-text-outline"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">New Message received</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">You have 87 unread messages</p>
                  </div>
                </div>
              </div>
            </a>

            <a href class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <span
                      class="avatar-title bg-info rounded-circle font-size-16"
                    >
                      <i class="mdi mdi-glass-cocktail"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">Your item is shipped</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      It is a long established fact that a reader will
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <span
                      class="avatar-title bg-primary rounded-circle font-size-16"
                    >
                      <i class="mdi mdi-cart-outline"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">Your order is placed</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      Dummy text of the printing and typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <span
                      class="avatar-title bg-danger rounded-circle font-size-16"
                    >
                      <i class="mdi mdi-message-text-outline"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">New Message received</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">You have 87 unread messages</p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="p-2 border-top">
            <div class="d-grid">
              <a
                class="btn btn-sm btn-link font-size-14 text-center"
                href="javascript:void(0)"
              >
                View all
              </a>
            </div>
          </div>
        </b-dropdown> -->
        
        <b-dropdown class="d-inline-block" right toggle-class="header-item" variant="white"
          menu-class="dropdown-menu-end">
          <template v-slot:button-content>
            <div class="d-flex align-items-center" style="gap:.8em">
              <div class="profile_view">
                <p class="mr-4">{{ profile.first_name }} {{ profile.last_name }}</p>
                <span class="badge rounded-pill bg-success lg badge-item" v-if="profile.user_type === 'staff'">{{profile.title !== null ? profile.title : profile.is_manager === 1 ? 'Manager' : 'Staff' }}</span>
                <span class="badge rounded-pill bg-success lg badge-item" v-if="profile.user_type !== 'staff'">{{ profile.user_type  }}</span>
              </div>
              
              <img class="rounded-circle header-profile-user ml-2" :src="avatar" alt="Header Avatar"
                v-if="profile.avatar === 'default.png' || profile.avatar === null" />
              <img class="rounded-circle header-profile-user ml-2" :src="getImage(profile.avatar)" alt="Header Avatar"
                v-else />
            </div>

          </template>
          <b-dropdown-item @click="showModal">
            <i class="mdi mdi-account-circle font-size-17 align-middle me-1"></i>
            Profile
          </b-dropdown-item>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="#" @click="logout">
            <i class="bx bx-power-off font-size-17 align-middle me-1 text-danger"></i>
            Logout
          </a>
        </b-dropdown>
      </div>
    </div>

    <b-modal id="profile-modal" title="Update Profile" title-class="font-18" size="lg" hide-footer @hide="hide">
      <div class="bp_form_box">
        <div class="bp_form_side_bar">
          <div v-for="(data, index) in tab_items" :key="index" class="bp_tab_link"
            :class="is_tab_active === data.id ? 'bp_active' : ''" @click="showFormContent(data.id)">
            <div class="bp_tab_counter">
              {{ index + 1 }}
            </div>
            <div class="bp_text_view">
              <span> {{ data.title }} </span>
            </div>
          </div>
        </div>

        <div class="bp_form_view">
          <div class="form_content">
            <div class="row" v-if="is_tab_active === 1">
              <div class="col-md-12">
                <!-- media -->

                <div class="d-flex align-items-center" style="gap: 1em">

                  <img class="rounded-circle header-profile-user-2 ml-2" :src="avatar" alt="Header Avatar"
                    style="width: 85px; height: 85px;" v-if="profile.avatar === 'default.png' || profile.avatar === null " />
                  <img class="rounded-circle header-profile-user-2 ml-2" :src="getImage(profile.avatar)" alt="Header Avatar"
                    style="width: 85px; height: 85px;" v-else />
                  <div>

                    <!-- upload button -->
                    <button type="button" class="btn btn-outline-info" @click="upload">
                      Upload
                    </button>
                    <!--/ upload button -->

                    <!-- reset -->
                    <!--/ reset -->
                    <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                  </div>
                </div>
                <!--/ media -->
              </div>

              <div class="col-md-12">
                <hr>
                <h4 class="mb-3 mt-3">Profile Info</h4>
                <form class="needs-validation">
                <div class="row">
                  
                  <div class="col-md-6">
                  <div class="mb-4">
                    <label class="form-label" for="validationCustom04">First Name</label>
                    <input id="validationCustom04" v-model="profile.first_name" type="text" class="form-control"
                      placeholder="" :class="{
                        'is-invalid': submitted && $v.profile.first_name.$error,
                      }" />
                    <div v-if="submitted && $v.profile.first_name.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.first_name.required">This value is required.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="mb-4">
                    <label class="form-label" for="validationCustom04">Last Name</label>
                    <input id="validationCustom04" v-model="profile.last_name" type="text" class="form-control"
                      placeholder="" :class="{
                        'is-invalid': submitted && $v.profile.last_name.$error,
                      }" />
                    <div v-if="submitted && $v.profile.last_name.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.last_name.required">This value is required.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="mb-4">
                    <label class="form-label" for="validationCustom04">Email</label>
                    <input id="validationCustom04" v-model="profile.email" type="text" class="form-control"
                      placeholder="" :class="{
                        'is-invalid': submitted && $v.profile.email.$error,
                      }" />
                    <div v-if="submitted && $v.profile.email.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.email.required">This value is required.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="mb-4">
                    <label class="form-label" for="validationCustom04">Phone</label>
                    <input id="validationCustom04" v-model="profile.phone" type="text" class="form-control"
                      placeholder="" :class="{
                        'is-invalid': submitted && $v.profile.phone.$error,
                      }" />
                    <div v-if="submitted && $v.profile.phone.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.phone.required">This value is required.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" v-if="profile.user_type !== 'staff' && profile.user_type !== 'admin'">
                  <div class="mb-4">
                    <label class="form-label" for="validationCustom04">Address</label>
                    <input id="validationCustom04" v-model="profile.address" type="text" class="form-control"
                      placeholder="" :class="{
                        'is-invalid': submitted && $v.profile.address.$error,
                      }" />
                    <div v-if="submitted && $v.profile.address.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.address.required">This value is required.</span>
                    </div>
                  </div>
                </div>


                <div class="col-md-6" v-if="profile.user_type !== 'staff' && profile.user_type !== 'admin'">
                  <div class="mb-4">
                    <label class="form-label" for="validationCustom04">City</label>
                    <input id="validationCustom04" v-model="profile.city" type="text" class="form-control"
                      placeholder="" :class="{
                        'is-invalid': submitted && $v.profile.city.$error,
                      }" />
                    <div v-if="submitted && $v.profile.city.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.city.required">This value is required.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3" v-if="profile.user_type !== 'staff' && profile.user_type !== 'admin'">
                  <div class="mb-4">
                    <label class="form-label" for="validationCustom04">State</label>
                    <input id="validationCustom04" v-model="profile.state" type="text" class="form-control"
                      placeholder="" :class="{
                        'is-invalid': submitted && $v.profile.state.$error,
                      }" />
                    <div v-if="submitted && $v.profile.state.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.state.required">This value is required.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-3" v-if="profile.user_type !== 'staff' && profile.user_type !== 'admin'">
                  <div class="mb-4">
                    <label class="form-label" for="validationCustom04">Zip Code</label>
                    <input id="validationCustom04" v-model="profile.zip_code" type="text" class="form-control"
                      placeholder="" :class="{
                        'is-invalid': submitted && $v.profile.zip_code.$error,
                      }" />
                    <div v-if="submitted && $v.profile.zip_code.$error" class="invalid-feedback">
                      <span v-if="!$v.profile.zip_code.required">This value is required.</span>
                    </div>
                  </div>
                </div>
                

                <div class="col-md-12" v-if="profile.user_type === 'staff'">
                  <hr />
                  <h4>Additional Infomation</h4>
                  <p class="mb-2 text-bold text-capitalize" v-if="profile.title != null"> <b>Title:</b> {{ profile.title }}</p>
                  <p class="mb-2 text-bold text-capitalize" v-if="profile.start_date != null"> <b>Start Date:</b> {{ formDate(profile.start_date) }}</p>
                </div>
                </div>

                

              </form>
              </div>
            </div>

            <div class="row" v-if="is_tab_active === 2">
            
              <form class="needs-validation">
                <div class="row">
                  
                  <div class="col-md-12">
                  <div class="mb-4 password-view">
                    <label class="form-label" for="validationCustom04">Old Password</label>
                    <input id="validationCustom04" v-model="password.old_password" :type="is_show_password ?  'text' : 'password'" class="form-control"
                      placeholder=""  />
                      <div class="password-visibile-box" @click="handleViewPassword">
                        <span class='mdi' :class="is_show_password ? 'mdi-eye-off' : 'mdi-eye'"></span>
                      </div>
                     
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="mb-4 password-view">
                    <label class="form-label" for="validationCustom04">New Password</label>
                    <input id="validationCustom04" v-model="password.new_password" :type="is_show_password ?  'text' : 'password'" class="form-control"
                      placeholder=""  />
                      <div class="password-visibile-box" @click="handleViewPassword">
                        <span class='mdi' :class="is_show_password ? 'mdi-eye-off' : 'mdi-eye'"></span>
                      </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="mb-4 password-view">
                    <label class="form-label" for="validationCustom04">Confirm Password</label>
                    <input id="validationCustom04" v-model="password.confirm_password" :type="is_show_password ?  'text' : 'password'" class="form-control"
                      placeholder="" @input="handleConfirmPasswordCheck" />
                      <div class="password-visibile-box" @click="handleViewPassword">
                        <span class='mdi' :class="is_show_password ? 'mdi-eye-off' : 'mdi-eye'"></span>
                      </div>
                      <span v-if="is_password_match" class="text-danger">Password does not match !</span>
                  </div>
                </div>

                </div>

              </form>
            </div>
          </div>
          <div class="button_view d-flex align-items-center justify-content-between" v-if="is_tab_active === 1">
            <button type="button" class="btn btn-outline-secondary" @click="hide">Cacel</button>
            <button type="button" class="btn btn-outline-success" @click="saveProfile">Save</button>
          </div>

          <div class="button_view d-flex align-items-center justify-content-between" v-if="is_tab_active === 2">
            <button type="button" class="btn btn-outline-secondary" @click="hide">Cacel</button>
            <button type="button" class="btn btn-outline-success" :disabled="password.new_password === password.confirm_password ? false : true" @click="savePassword">Save</button>
          </div>

        </div>
      </div>
    </b-modal>
  </header>
</template>
