const user_type = localStorage.getItem("sweet_homes_america_user_type");
const user_data = JSON.parse(localStorage.getItem("sweet_homes_america_user"));

let menuItems = [];

if (user_type === "admin") {
  menuItems = [
    {
      id: 1,
      label: "Main",
      isTitle: true,
    },
    {
      id: 2,
      label: "Dashboard",
      icon: "ti-layout-grid2",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/admin-dashboard",
    },
    {
      id: 3,
      label: "Staff",
      icon: "ti-user",
      link: "/all-staff",
    },
    {
      id: 14,
      label: 'Website Contents',
      icon: 'ti-settings',
      subItems: [
        {
          id: 6,
          label: "Team Members",
          link: "/team-members",
        },
        {
          id: 4,
          label: "FAQs",
          link: "/faqs",
        },
        {
          id: 5,
          label: "Privacy Policy",
          link: "/privacy-policy",
        },
        {
          id: 6,
          label: "Terms Of Service",
          link: "/terms-of-service",
        },
      ]
      },
    {
      id: 16,
      label: 'Settings',
      icon: 'ti-settings',
      subItems: [
        {
          id: 7,
          label: "Configurations",
          link: "/configurations",
        },
      ]
      },
  ];
} else if (user_type === "staff" && user_data.is_manager === 1) {
  menuItems = [
    {
      id: 1,
      label: "Main",
      isTitle: true,
    },
    {
      id: 2,
      label: "Dashboard",
      icon: "ti-layout-grid2",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/",
    },

    {
      id: 7,
      label: "Properties",
      icon: "ti-home",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/properties",
    },

    {
      id: 4,
      label: "Clients",
      icon: "ti-user",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/clients",
    },
    {
      id: 5,
      label: "Tenants",
      icon: "ti-user",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/tenants",
    },

    {
      id: 6,
      label: "Vendors",
      icon: "ti-user",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/vendors",
    },
    {
      id: 3,
      label: "Staff",
      icon: "ti-user",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/staffs",
    },

    {
      id: 15,
      label: 'Accounting',
      icon: 'ti-wallet',
      subItems: [
        {
          id: 8,
          label: "Expenses",
          icon: "ti-clipboard",
          //  badge: {
          //      variant: "primary",
          //      text: "2"
          //  },
          link: "/expenses",
        },
    
        {
          id: 10,
          label: "Payments",
          icon: "ti-wallet",
          //  badge: {
          //      variant: "primary",
          //      text: "2"
          //  },
          link: "/payments",
        },
        {
          id: 9,
          label: "Monthly Report",
          icon: "ti-agenda",
          //  badge: {
          //      variant: "primary",
          //      text: "2"
          //  },
          link: "/monthly-reports",
        },
      ]
    },
    {
      id: 11,
      label: 'Settings',
      icon: 'ti-settings',
      subItems: [
        {
          id: 12,
          label: "Amenities",
          icon: "ti-view-list-alt",
          //  badge: {
          //      variant: "primary",
          //      text: "2"
          //  },
          link: "/amenity",
        },
        {
          id: 13,
          label: "Parking Type",
          icon: "ti-view-list-alt",
          //  badge: {
          //      variant: "primary",
          //      text: "2"
          //  },
          link: "/parking-type",
        },
        {
          id: 14,
          label: "Property Type",
          icon: "ti-view-list-alt",
          //  badge: {
          //      variant: "primary",
          //      text: "2"
          //  },
          link: "/property-type",
        },
        {
          id: 15,
          label: "Utilities Included",
          icon: "ti-view-list-alt",
          //  badge: {
          //      variant: "primary",
          //      text: "2"
          //  },
          link: "/utility-included",
        },

        {
          id: 16,
          label: "Expenses Category",
          icon: "ti-view-list-alt",
          //  badge: {
          //      variant: "primary",
          //      text: "2"
          //  },
          link: "/expenses-categories",
        },

        {
          id: 17,
          label: "Payment Category",
          icon: "ti-list",
          //  badge: {
          //      variant: "primary",
          //      text: "2"
          //  },
          link: "/payment-categories",
        },
        {
          id: 18,
          label: "Vendor Type",
          icon: "ti-list",
          //  badge: {
          //      variant: "primary",
          //      text: "2"
          //  },
          link: "/vendor-types",
        },
        {
          id: 19,
          label: "Forms of Payment",
          icon: "ti-list",
          //  badge: {
          //      variant: "primary",
          //      text: "2"
          //  },
          link: "/form-of-payments",
        },
      ]
    },
  ];
} else if (user_type === "staff" && user_data.is_manager === 0) {
  menuItems = [
    {
      id: 1,
      label: "Main",
      isTitle: true,
    },
    {
      id: 2,
      label: "Dashboard",
      icon: "ti-layout-grid2",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/staff-dashboard",
    },
    {
      id: 3,
      label: "Properties",
      icon: "ti-home",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/properties",
    },
    {
      id: 4,
      label: "Clients",
      icon: "ti-user",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/clients",
    },
    {
      id: 5,
      label: "Tenants",
      icon: "ti-user",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/tenants",
    },
    {
      id: 6,
      label: "Vendors",
      icon: "ti-user",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/vendors",
    },

    {
      id: 15,
      label: 'Accounting',
      icon: 'ti-wallet',
      subItems: [
        {
          id: 8,
          label: "Expenses",
          icon: "ti-clipboard",
          //  badge: {
          //      variant: "primary",
          //      text: "2"
          //  },
          link: "/expenses",
        },
    
        {
          id: 10,
          label: "Payments",
          icon: "ti-wallet",
          //  badge: {
          //      variant: "primary",
          //      text: "2"
          //  },
          link: "/payments",
        },
        // {
        //   id: 9,
        //   label: "Monthly Report",
        //   icon: "ti-agenda",
        //   //  badge: {
        //   //      variant: "primary",
        //   //      text: "2"
        //   //  },
        //   link: "/monthly-reports",
        // },
      ]
    },
    // {
    //   id: 7,
    //   label: "Monthly Report",
    //   icon: "ti-wallet",
    //   //  badge: {
    //   //      variant: "primary",
    //   //      text: "2"
    //   //  },
    //   link: "/monthly-reports",
    // },
    // {
    //   id: 15,
    //   label: 'Accounts',
    //   icon: 'ti-user',
    //   subItems: [
      
    //   ]
    // },
    // {
    //   id: 11,
    //   label: 'Settings',
    //   icon: 'ti-settings',
    //   subItems: [


    //     {
    //       id: 12,
    //       label: "Expenses Category",
    //       icon: "ti-view-list-alt",
    //       //  badge: {
    //       //      variant: "primary",
    //       //      text: "2"
    //       //  },
    //       link: "/expenses-categories",
    //     },

    //     {
    //       id: 13,
    //       label: "Payment Category",
    //       icon: "ti-list",
    //       //  badge: {
    //       //      variant: "primary",
    //       //      text: "2"
    //       //  },
    //       link: "/payment-categories",
    //     },
    //     {
    //       id: 14,
    //       label: "Vendor Type",
    //       icon: "ti-list",
    //       //  badge: {
    //       //      variant: "primary",
    //       //      text: "2"
    //       //  },
    //       link: "/vendor-types",
    //     },
    //     {
    //       id: 15,
    //       label: "Forms of Payment",
    //       icon: "ti-list",
    //       //  badge: {
    //       //      variant: "primary",
    //       //      text: "2"
    //       //  },
    //       link: "/form-of-payments",
    //     },
    //   ]
    //   },


  ];
} else if (user_type === "tenant") {
  menuItems = [
    {
      id: 1,
      label: "Main",
      isTitle: true,
    },
    {
      id: 2,
      label: "Dashboard",
      icon: "ti-layout-grid2",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/",
    },
    {
      id: 3,
      label: "Properties",
      icon: "ti-home",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/properties",
    },
  ];
} else if (user_type === "property_owner") {
  menuItems = [
    {
      id: 1,
      label: "Main",
      isTitle: true,
    },
    {
      id: 2,
      label: "Dashboard",
      icon: "ti-layout-grid2",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/",
    },
    {
      id: 3,
      label: "Properties",
      icon: "ti-home",
      //  badge: {
      //      variant: "primary",
      //      text: "2"
      //  },
      link: "/properties",
    },
  ];
}

export { menuItems };
