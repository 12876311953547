import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const authUserComputed = {
    ...mapState('authuser', {
        user:(state) => state.user,
    }),
    ...mapGetters('authuser', ['getUser'])
}

// export email account actions
export const authUserMethods = mapActions('authuser', [
    'showUser','loginUser',
    'allAdmin','allManager','allStaffs','allClient','allVendors','allTenants', 'forgetPassword',
     'registerUser', 'registerManager',
     'registerStaff', 'registerAdmin','registerClient', 'updateVisibileToWebsitePosition',
     'registerTenant','registerVendor',
    'updateUser','updateStaff','updateManager','updateAdmin','resetPassword', 'updateClient','updateTenant','updateVendor',
     'showUserAccess', 'toggleUserAccess',
    'deleteUser', 'logoutUser', 'showTenant', 'showClient', 'showStaff', 'showVendor'])
